import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "@emotion/styled"
import { device } from "../utils/mixins"

import Layout from "../components/layout"
import SEO from "../components/seo"

import {
  PageTopFullSizeImgCont,
  SectionContentContainer,
  PersonaContainer,
} from "../components/imageComponents"
import {
  PageSectionTitle,
  I2BronzeText,
  SectionTitleText,
  BodyText,
  BodyBold,
} from "../components/textComponents"
import {
  PageSectionButton,
  PageImageButton,
  LinkedInButton,
} from "../components/interactiveComponents"

var Image = styled(Img)`
  max-height: 15rem;
  position: absolute;

  ${device.mobileX} {
    max-height: 20rem;
  }

  ${device.tablet} {
    max-height: 23rem;
  }
`

var FounderButtonContainer = styled.div`
  ${device.tablet} {
    display: flex;
    justify-content: space-between;
    margin: -0.5rem 0;
  }
`

function AboutUs() {
  var data = useStaticQuery(graphql`
    query {
      aboutTopImg: file(
        relativePath: { eq: "About_i2_Oil_and_Gas_Optimization.jpeg" }
      ) {
        ...fluidImageSVG
      }

      aboutMattImg: file(
        relativePath: { eq: "Matt_Deady_i2_Optimization_Fort_St_John.jpeg" }
      ) {
        ...fluidImageSVG
      }
    }
  `)

  return (
    <Layout>
      <SEO
        title="About i2O"
        description="Learn more about i2O. We are Completions Specialists with an Expert Understanding of CNG and Bi-Fuel Horsepower."
      />
      <PageTopFullSizeImgCont>
        <Image
          style={{ height: "inherit" }}
          objectFit="cover"
          fluid={data.aboutTopImg.sharp.fluid}
          alt="i2 - Optimizing Well Site Operations Across Western Canada."
        />
        <PageSectionTitle>
          About <I2BronzeText>i2O</I2BronzeText>
        </PageSectionTitle>
        <PageImageButton to="/contact-us/">Contact Us</PageImageButton>
      </PageTopFullSizeImgCont>

      <SectionContentContainer>
        <SectionTitleText>
          The <I2BronzeText>i2O</I2BronzeText> Story
        </SectionTitleText>

        <BodyText>
          After more than 20 years in Drilling, Production, and Completions
          operations,{" "}
          <BodyBold>
            <I2BronzeText>i2O</I2BronzeText>
          </BodyBold>
          ’s founder realized that{" "}
          <BodyBold>
            most oil and gas operations leave a lot on the table in terms of
            wasted resources and capital.
          </BodyBold>
        </BodyText>

        <BodyText>
          <BodyBold>
            <I2BronzeText>i2</I2BronzeText> Optimization{" "}
          </BodyBold>
          came into existence to assist oil and gas producers looking to get
          more value from their operations. We employ industry expertise and
          utilize innovative technology to help you get more output with less
          input. <BodyBold>A lot less.</BodyBold>
        </BodyText>
      </SectionContentContainer>

      <SectionContentContainer>
        <SectionTitleText>
          Our <I2BronzeText>Founder</I2BronzeText>
        </SectionTitleText>
        <PersonaContainer
          name="Matt Deady"
          title="President/CEO"
          quote="“My goal for your operation isn't to take it up a level. Or do things a bit better. Let’s be an industry leader.”"
        >
          <Image
            style={{ height: "inherit" }}
            objectFit="cover"
            fluid={data.aboutMattImg.sharp.fluid}
            alt="i2 - Matt Deady, Oil and Gas Optimization Consultant"
          />
        </PersonaContainer>
        <BodyText>
          Since 2000, Matt has worked with innovative companies like Cathedral
          Energy and FMC Technologies across{" "}
          <BodyBold>Drilling, Completions,</BodyBold> and{" "}
          <BodyBold>Production</BodyBold> operations.
        </BodyText>

        <BodyText>
          <BodyBold>
            Utilizing new technology and applying industry experience to get
            exceptional results is what drives Matt.
          </BodyBold>
        </BodyText>

        <FounderButtonContainer>
          <PageSectionButton to="/industry-experience/">
            Industry Experience
          </PageSectionButton>
          <LinkedInButton href="https://www.linkedin.com/in/matt-deady-6015712a/">
            LinkedIn Profile
          </LinkedInButton>
        </FounderButtonContainer>
      </SectionContentContainer>
    </Layout>
  )
}

export default AboutUs
